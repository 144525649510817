import {
    Component,
    OnInit,
    ViewEncapsulation,
    Inject,
    ViewChild,
} from '@angular/core';
import { GoogleMap, MapMarker, MapPolygon } from '@angular/google-maps';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MouseEvent, Polygon } from '@agm/core';
import { AdpLatLng } from 'app/shared/services/orchards.service';
import { Farm, FarmsService } from 'app/shared/services/farm.service';

@Component({
    selector: 'app-map-area-builder',
    templateUrl: './map-area-builder.component.html',
    styleUrls: ['./map-area-builder.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MapAreaBuilderComponent implements OnInit {
    center: any;
    radius: number = 12;
    @ViewChild('map') googleMap: GoogleMap;

    options: google.maps.PolygonOptions;
    overlay: google.maps.GroundOverlay;
    otherOptions: google.maps.PolygonOptions = {
        strokeColor: '#3676b7',
        strokeWeight: 2,
        fillColor: '#3676b7',
        zIndex: 1,
    };

    parentOptions: google.maps.PolygonOptions = {
        strokeColor: '#ff6500',
        strokeWeight: 2,
        fillColor: '#ff6500',
        zIndex: -1,
    };

    locations: google.maps.LatLngLiteral[] = [];
    lastSavedLocations: google.maps.LatLngLiteral[] = [];
    otherBoundaries: AdpLatLng[][] = [];
    parentBoundary: AdpLatLng[] = [];
    color: string = 'green';

    markerOptions: google.maps.MapOptions = {
        mapTypeId: 'roadmap',
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: true,
        maxZoom: 15,
        minZoom: 8,
    };

    marker: MapMarker;
    farm: Farm;

    constructor(
        private _farmService: FarmsService,
        public dialogRef: MatDialogRef<MapAreaBuilderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.otherBoundaries = this.data.others;
        this.parentBoundary = this.data.parentBoundary || [];
        if (this.data.bounds) {
            this.locations = this.data.bounds;
            this.lastSavedLocations = this.locations.slice();
        }
    }

    async ngOnInit(): Promise<void> {
        this._farmService.farm$.subscribe((farm) => {
            const bounds = new google.maps.LatLngBounds();
            this.farm = farm;
            this.farm.bounds.forEach((loc) => {
                bounds.extend(new google.maps.LatLng(loc.lat, loc.lng));
            });
            this.googleMap.fitBounds(bounds);
        });

        this._farmService.farm$.subscribe(async (farm) => {
            let center;
            if (this.data.bounds) {
                center = farm.bounds[0];
            } else {
                center = await this.getPosition();
            }

            this.center = center;

            this.options = {
                fillColor: this.color,
                strokeColor: this.color,
                strokeWeight: 2,
                paths: this.locations,
                zIndex: 2,
            };
        });
    }

    save() {
        let latLongs: google.maps.LatLng[] = this.locations.map((loc) => {
            return new google.maps.LatLng(loc.lat, loc.lng);
        });

        const areaM2 = Math.trunc(
            google.maps.geometry.spherical.computeArea(latLongs)
        );
        this.dialogRef.close({ locations: this.locations, area: areaM2 });
    }

    undo() {
        this.locations.pop();
        this.options = {
            fillColor: this.color,
            strokeColor: this.color,
            strokeWeight: 2,
            paths: this.locations,
            zIndex: 2,
        };

        this.marker = {
            position: {
                lat: this.locations[this.locations.length - 1].lat,
                lng: this.locations[this.locations.length - 1].lng,
            },
        } as MapMarker;
    }

    mapClicked($event: any): void {
        this.locations.push({
            lat: $event.latLng.lat(),
            lng: $event.latLng.lng(),
        });

        // Doesn't work unless I set this options object every time the locations changes, no idea why.
        this.options = {
            fillColor: this.color,
            strokeColor: this.color,
            strokeWeight: 2,
            paths: this.locations,
            zIndex: 2,
        };

        this.marker = {
            position: {
                lat: $event.latLng.lat(),
                lng: $event.latLng.lng(),
            },
        } as MapMarker;
    }

    handleClick(event: any) {
        this.locations.push({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        });

        this.options = {
            fillColor: this.color,
            strokeColor: this.color,
            strokeWeight: 2,
            paths: this.locations,
            zIndex: 2,
        };
    }

    handleRightclick(event: any) {}

    cancel() {
        this.dialogRef.close(this.lastSavedLocations);
    }

    getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (resp) => {
                    resolve({
                        lng: resp.coords.longitude,
                        lat: resp.coords.latitude,
                    });
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    clear() {
        this.locations = [];
        this.options = {
            fillColor: this.color,
            strokeColor: this.color,
            strokeWeight: 2,
            paths: this.locations,
            zIndex: 2,
        };
        this.marker = null;
    }

    openInfo(marker: MapMarker, content: any) {
        // console.log(marker);
        // console.log(content);
    }
}
