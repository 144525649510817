import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { PublicLayoutComponent } from './shared/components/layouts/public-layout/public-layout.component';

export const rootRouterConfig: Routes = [
    {
        path: '',
        redirectTo: 'farm/dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'sessions',
                loadChildren: () =>
                    import('./views/sessions/sessions.module').then(
                        (m) => m.SessionsModule
                    ),
                data: { title: 'Session' },
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('./views/fpp/fpp.module').then(
                        (m) => m.FreshProducePlatformModule
                    ),
                data: { title: 'Home', breadcrumb: 'HOME' },
            },
            {
                path: 'search',
                loadChildren: () =>
                    import('./views/search-view/search-view.module').then(
                        (m) => m.SearchViewModule
                    ),
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'cycle',
                loadChildren: () =>
                    import('./views/crop-cycles/crop-cycle.module').then(
                        (m) => m.CropCycleModule
                    ),
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'compliance',
                loadChildren: () =>
                    import('./views/compliance/compliance.module').then(
                        (m) => m.ComplianceModule
                    ),
                data: { title: 'Compliance', breadcrumb: 'Compliance' },
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'inventory',
                loadChildren: () =>
                    import('./views/inventory/inventory.module').then(
                        (m) => m.InventoryModule
                    ),
                data: { title: 'Inventory', breadcrumb: 'Inventory' },
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'fields',
                loadChildren: () =>
                    import('./views/fields/fields.module').then(
                        (m) => m.FieldsModule
                    ),
                data: { title: 'Fields', breadcrumb: 'Fields' },
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'orchards',
                loadChildren: () =>
                    import('./views/orchards/orchards.module').then(
                        (m) => m.OrchardsModule
                    ),
                data: { title: 'Orchards', breadcrumb: 'Orchards' },
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'general',
                loadChildren: () =>
                    import('./views/general/general.module').then(
                        (m) => m.GeneralModule
                    ),
                data: { title: '', breadcrumb: '' },
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'scouting',
                loadChildren: () =>
                    import('./views/scouting/scouting.module').then(
                        (m) => m.ScoutingModule
                    ),
                data: { title: 'Scouting', breadcrumb: 'Scouting' },
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'farm',
                loadChildren: () =>
                    import('./views/farm/farm.module').then(
                        (m) => m.FarmModule
                    ),
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'harvesting',
                loadChildren: () =>
                    import('./views/harvesting/harvesting.module').then(
                        (m) => m.HarvestingModule
                    ),
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'reporting',
                loadChildren: () =>
                    import('./views/reporting/reporting.module').then(
                        (m) => m.ReportingModule
                    ),
                data: { title: 'Reporting', breadcrumb: 'Reporting' },
            },
        ],
    },
    {
        path: '',
        component: PublicLayoutComponent,
        children: [
            {
                path: 'public',
                loadChildren: () =>
                    import('./views/public/public.module').then(
                        (m) => m.PublicModule
                    ),
                data: {},
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'sessions/404',
    },
];
