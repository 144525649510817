<div class="sidebar-panel">
    <div
        id="scroll-area"
        [perfectScrollbar]
        class="navigation-hold"
        fxLayout="column"
    >
        <div class="sidebar-hold">
            <!-- App Logo -->
            <div class="adpGradient branding">
                <img src="assets/images/fppLogo.png" alt="" class="app-logo" />
                <!-- <span class="app-logo-text">ADP</span> -->

                <span
                    style="margin: auto;"
                    *ngIf="layoutConf.sidebarStyle !== 'compact'"
                ></span>
                <div
                    class="sidebar-compact-switch"
                    [ngClass]="{ active: layoutConf.sidebarCompactToggle }"
                    (click)="toggleCollapse()"
                    *ngIf="layoutConf.sidebarStyle !== 'compact'"
                >
                    <span></span>
                </div>
            </div>

            <!-- Sidebar user -->
            <div class="app-user">
                <div class="app-user-photo">
                    <img
                        [src]="jwtAuth.user?.imageUrl"
                        class="mat-elevation-z1"
                        alt=""
                    />
                </div>
                <span class="app-user-name mb-05">
                    <mat-icon class="icon-xs text-muted">lock</mat-icon>
                    <strong
                        >{{ jwtAuth?.user?.name }}
                        {{ jwtAuth?.user?.surname }}</strong
                    >
                </span>
                <!-- Small buttons -->
                <div class="app-user-controls">
                    <button
                        class="text-muted"
                        mat-icon-button
                        mat-xs-button
                        [matMenuTriggerFor]="appUserMenu"
                    >
                        <mat-icon>settings</mat-icon>
                    </button>
                    <!-- <button
                        class="text-muted"
                        mat-icon-button
                        mat-xs-button
                        matTooltip="Inbox"
                        routerLink="/inbox"
                    >
                        <mat-icon>email</mat-icon>
                    </button> -->
                    <button
                        class="text-muted"
                        mat-icon-button
                        mat-xs-button
                        matTooltip="Sign Out"
                        routerLink="/sessions/signin2"
                    >
                        <mat-icon>exit_to_app</mat-icon>
                    </button>
                    <mat-menu #appUserMenu="matMenu">
                        <button mat-menu-item routerLink="/home/profile">
                            <mat-icon>account_box</mat-icon>
                            <span>Profile</span>
                        </button>
                        <!-- <button mat-menu-item routerLink="/profile/settings">
                            <mat-icon>settings</mat-icon>
                            <span>Account Settings</span>
                        </button> -->
                        <!-- <button mat-menu-item routerLink="/calendar">
                            <mat-icon>date_range</mat-icon>
                            <span>Calendar</span>
                        </button> -->
                        <button mat-menu-item (click)="jwtAuth.signout()">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>Sign out</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <!-- Navigation -->
            <app-sidenav
                [items]="menuItems"
                [hasIconMenu]="hasIconTypeMenuItem"
                [iconMenuTitle]="iconTypeMenuTitle"
            ></app-sidenav>
        </div>
    </div>
</div>
