import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditCommunityProjectsComponent } from 'app/views/general/manage-projects/edit-community-projects/edit-community-projects.component';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.css'],
})
export class MessageDialogComponent implements OnInit {
    title: string;
    message: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EditCommunityProjectsComponent>
    ) {}

    ngOnInit() {
        this.title = this.data.title;
        this.message = this.data.message;
    }

    onCancel() {
        this.dialogRef.close(false);
    }

    onConfirm() {
        this.dialogRef.close(true);
    }

    deleteGroup() {
        this.dialogRef.close({ groupId: this.data.groupId });
    }
}
