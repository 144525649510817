import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { switchMap, map, take } from 'rxjs/operators';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { UserModel } from '../models/user.model';
import { FarmsService } from './farm.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

export class LinkedUser {
    id?: string;
    name: string;
}

@Injectable()
export class UsersService {
    sellers$: Observable<UserModel[]>;
    linkedUsers$: Observable<UserModel[]>;

    constructor(
        private _authService: AuthService,
        private _firestore: AngularFirestore,
        private _farmService: FarmsService,
        private _http: HttpClient
    ) {
        this.sellers$ = this._authService.user$.pipe(
            switchMap((user) => {
                return this._getOrders((ref) =>
                    ref.where('seller', '==', true)
                );
            })
        );

        this.linkedUsers$ = this._farmService.farm$.pipe(
            switchMap((farm) => {
                return this._getLinkedUsers((ref) =>
                    ref.where('farmId', '==', farm.id)
                );
            })
        );
    }

    _getOrders(queryFn: QueryFn): Observable<UserModel[]> {
        return this._firestore
            .collection<UserModel>('users', queryFn)
            .snapshotChanges()
            .pipe(
                map((products) => {
                    return products.map((action) => {
                        const prod = action.payload.doc.data() as UserModel;
                        prod.id = action.payload.doc.id;
                        return prod;
                    });
                })
            );
    }

    _getLinkedUsers(queryFn: QueryFn): Observable<UserModel[]> {
        return this._firestore
            .collection<UserModel>('users', queryFn)
            .snapshotChanges()
            .pipe(
                map((users) => {
                    return users.map((action) => {
                        const user = action.payload.doc.data() as UserModel;
                        user.id = action.payload.doc.id;
                        return user;
                    });
                })
            );
    }

    searchForUser(email: string): Observable<UserModel> {
        return this._firestore
            .collection('users', (ref) => ref.where('email', '==', email))
            .snapshotChanges()
            .pipe(
                take(1),
                map((actions) => {
                    if (actions.length > 0) {
                        const user = actions[0].payload.doc.data() as UserModel;
                        user.id = actions[0].payload.doc.id;
                        return user;
                    }
                    return null;
                })
            );
    }

    async addFarmIdToUser(user: UserModel, farmId: string): Promise<void> {
        return this._firestore
            .collection('users')
            .doc(user.id)
            .update({ farmId });
    }

    async createNewUser(
        user: any
    ): Promise<{ success: boolean; uid?: string; error?: any }> {
        const idToken = this._authService.idToken;
        const headers = new HttpHeaders().set('id-token', idToken);

        return this._http
            .post<{ success: boolean; uid?: string; error?: any }>(
                `${environment.apiURL}/users/createLinkedUser`,
                user,
                {
                    headers: headers,
                }
            )
            .toPromise();
    }

    async removeLinkedUser(user: UserModel): Promise<void> {
        return this._firestore
            .collection('users')
            .doc(user.id)
            .update({ farmId: null });
    }
}
