import { Injectable } from '@angular/core';
import { LocalStoreService } from '../local-store.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {
    map,
    catchError,
    delay,
    shareReplay,
    switchMap,
    filter,
} from 'rxjs/operators';
import { UserModel, NewAccountModel } from '../../models/user.model';
import { of, BehaviorSubject, throwError, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import {
    AngularFirestore,
    DocumentSnapshot,
    DocumentReference,
} from '@angular/fire/firestore';
import { AppLoaderService } from '../app-loader/app-loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReturnStatement } from '@angular/compiler';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    token;
    isAuthenticated: Boolean;
    user: UserModel = new UserModel();
    user$ = new Observable<UserModel>();
    signingIn: Boolean;

    idToken$: Observable<string>;
    idToken: string;

    currentUser: User;
    currentUser$: Observable<User>;
    _loggedInSubject = new BehaviorSubject<User>(null);

    constructor(
        private ls: LocalStoreService,
        private router: Router,
        public auth: AngularFireAuth,
        private _firestore: AngularFirestore,
        private _loader: AppLoaderService,
        private _snackbar: MatSnackBar,
        private _router: Router
    ) {
        this.currentUser$ = this.auth.authState.pipe(
            shareReplay({
                bufferSize: 1,
                refCount: true,
            })
        );

        // this._auth.authState.subscribe((res) => {
        //     if (res !== null) {
        //         this._router.navigateByUrl('/home');
        //     }
        // });.

        this.idToken$ = this.auth.idToken.pipe(
            shareReplay({
                bufferSize: 1,
                refCount: true,
            })
        );

        this.idToken$.subscribe((idToken) => {
            this.idToken = idToken;
        });

        this.currentUser$.subscribe((user) => {
            if (user) {
                console.log('uid', user.uid);
            }
            this.currentUser = user;
        });

        this.user$ = this.currentUser$.pipe(
            filter((user) => user != null),
            switchMap((u) => {
                return this._getUserInfo(u.uid);
            }),
            catchError((e) => {
                console.error('error getting user ', e);
                return of<UserModel>(null);
            }),
            shareReplay({
                bufferSize: 1,
                refCount: true,
            })
        );

        this.user$.subscribe((u) => {
            this.user = u;
        });
    }

    _getUserInfo(uid: string): Observable<UserModel> {
        return this._firestore
            .collection('users')
            .doc<UserModel>(uid)
            .valueChanges()
            .pipe(
                map((user) => {
                    if (!user.roles) {
                        user.roles = [];
                    }
                    return new UserModel({ uid, ...user });
                }),
                catchError((e) => {
                    console.error('error in _getUserInfo', e);
                    return of<UserModel>(null);
                })
            );
    }

    public async signin(email: string, password: string) {
        try {
            this._loader.open();
            let loginPromise: Promise<firebase.auth.UserCredential>;
            const cred = await this.auth.signInWithEmailAndPassword(
                email,
                password
            );
            loginPromise = Promise.resolve(cred);
            if (cred) {
                this._snackbar.open('Welcome back', null, {
                    duration: 5000,
                    verticalPosition: 'top',
                });
            }

            const credential: firebase.auth.UserCredential = await loginPromise;
            this._loggedInSubject.next(credential.user);
            this._loader.close();
        } catch (e) {
            this._snackbar.open(e.message, null, {
                verticalPosition: 'top',
            });
            this._loader.close();
        }
    }

    public async createUser(
        email: string,
        password: string
    ): Promise<firebase.auth.UserCredential> {
        return this.auth.createUserWithEmailAndPassword(email, password);
    }

    public signout() {
        // this.setUserAndToken(null, null, false);
        this.auth.signOut();
        this.router.navigateByUrl('sessions/signin2');
    }

    async isLoggedIn(): Promise<Boolean> {
        return (await this.auth.currentUser) != null;
        // let user = await this.waitForUser();
        // return user != null;
    }

    async waitForUser(): Promise<User> {
        return new Promise((resolve, reject) => {
            setInterval(async () => {
                let user = await this.auth.currentUser;
                resolve(user);
            }, 200);
        });
    }

    async waitForUserModel(): Promise<UserModel> {
        // if (!this.user) {
        //     return await this.user$.toPromise();
        // }
        // return this.user;

        return new Promise((resolve, reject) => {
            let wait = setInterval(async () => {
                // let user = await this.auth.currentUser;
                if (this.user) {
                    clearInterval(wait);
                    resolve(this.user);
                }
            }, 500);
        });
    }

    getJwtToken() {
        return this.idToken;
    }
    getUser() {
        return this.user;
        // return this.ls.getItem(this.APP_USER);
    }

    async createAccount(newAccount: NewAccountModel): Promise<any> {
        try {
            let cred = await this.auth.createUserWithEmailAndPassword(
                newAccount.email,
                newAccount.password
            );

            if (cred.user) {
                delete newAccount.password;
                let userModel = newAccount as UserModel;
                userModel.roles = ['admin'];
                return await this._firestore
                    .collection('users')
                    .doc(cred.user.uid)
                    .set(Object.assign({}, userModel));
            }
        } catch (err) {
            this._snackbar.open(err.message, null, {
                duration: 5000,
                verticalPosition: 'top',
            });
        }
    }

    setUserAndToken(token: String, user: UserModel, isAuthenticated: Boolean) {
        this.isAuthenticated = isAuthenticated;
        this.token = token;
        this.user = user;
        // this.user$.next(user);
    }

    async updateUser(update: any): Promise<void> {
        return await this._firestore
            .collection('users')
            .doc(update.uid)
            .update(update);
    }
}
