import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
    AngularFirestore,
} from '@angular/fire/firestore';
import { MarketOrder } from './orders.service';

export class Cart {
    products: MarketOrder[] = [];
    total: number = 0;
    buyerName: string;
}

@Injectable()
export class CartService {
    cart$: Observable<Cart>;
    cart: BehaviorSubject<Cart> = new BehaviorSubject(new Cart());

    constructor(
        private _firestore: AngularFirestore
    ) {
        this.cart$ = this.cart;

        let cartJson = localStorage.getItem('orderCart');

        if (cartJson) {
            let cart = JSON.parse(cartJson) as Cart;
            this.cart.next(cart);
        }
    }

    public async addToCart(order: MarketOrder) {
        let cart = this.cart.value;
        cart.products.push(order);
        this.cart.next(cart);

        localStorage.setItem('orderCart', JSON.stringify(cart));

        // console.log(order);
        // return this._firestore
        //     .collection('orders')
        //     .add(Object.assign({}, order));
    }

    public async editOrder(product: MarketOrder): Promise<void> {
        return this._firestore
            .collection('products')
            .doc(product.id)
            .update(Object.assign({}, product));
    }
}
