import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { TranslateModule } from "@ngx-translate/core";
import { DatetimeRangeSelectorDialogComponent } from "./datetime-range-selector-dialog/datetime-range-selector-dialog.component";
import { DatetimeRangeSelectorComponent } from "./datetime-range-selector.component";

@NgModule({
    declarations: [
        DatetimeRangeSelectorComponent,
        DatetimeRangeSelectorDialogComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatCardModule,
        MatListModule,
        MatChipsModule,
        MatTableModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatDatepickerModule,
        MatPaginatorModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TranslateModule,
    ],
    entryComponents: [DatetimeRangeSelectorDialogComponent],
    exports: [DatetimeRangeSelectorComponent],
})
export class DatetimeRangeSelectorModule {}
