import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { firestore } from 'firebase';
import { AuthService } from './auth/auth.service';
import { switchMap, map, catchError, filter } from 'rxjs/operators';
import {
    AngularFirestore,
    DocumentReference,
    QueryFn,
} from '@angular/fire/firestore';

export class MarketOrder {
    id?: string;
    productId: string;
    quantity: number;
    orderDate: Date;
    buyerId: string;
    sellerId: string;
    status: number;
    buyerName: string;
    sellerName: string;
    productName: string;
    units: string;
    pricePerUnit: number;
    orderTotal: number;
    currency: string;
    productExpiry: Date;
}

@Injectable()
export class OrdersService {
    ordersReceived$: Observable<MarketOrder[]>;
    ordersPlaced$: Observable<MarketOrder[]>;

    constructor(
        private _authService: AuthService,
        private _firestore: AngularFirestore
    ) {
        this.ordersReceived$ = this._authService.user$.pipe(
            filter((user) => user != null),
            switchMap((user) => {
                return this._getOrders((ref) =>
                    ref.where('sellerId', '==', user.uid)
                );
            })
        );

        this.ordersPlaced$ = this._authService.user$.pipe(
            filter((user) => user != null),
            switchMap((user) => {
                return this._getOrders((ref) =>
                    ref.where('buyerId', '==', user.uid)
                );
            })
        );
    }

    _getOrders(queryFn: QueryFn): Observable<MarketOrder[]> {
        return this._firestore
            .collection<MarketOrder>('orders', queryFn)
            .snapshotChanges()
            .pipe(
                map((products) => {
                    return products.map((action) => {
                        const prod = action.payload.doc.data() as MarketOrder;
                        prod.id = action.payload.doc.id;
                        return prod;
                    });
                })
            );
    }

    public async createOrder(order: MarketOrder): Promise<DocumentReference> {
        console.log(order);
        return this._firestore
            .collection('orders')
            .add(Object.assign({}, order));
    }

    public async editOrder(product: MarketOrder): Promise<void> {
        return this._firestore
            .collection('products')
            .doc(product.id)
            .update(Object.assign({}, product));
    }
}
