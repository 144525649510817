export class UserModel {
    id?: string;
    displayName?: string;
    role?: string;
    uid?: string;
    buyer: boolean;
    seller: boolean;
    email: string;
    name: string;
    surname: string;
    address: string;
    currency: string;
    latitude?: number;
    longitude?: number;
    imageUrl?: string;
    farmId?: string;
    linkedFarm?: string;
    roles?: string[] = [];
    surveyIds?: string[] = [];
    scoutingFarms?: string[] = [];

    get isAdmin(): boolean {
        return this.roles.includes('admin');
    }

    get isManager(): boolean {
        if (this.roles.includes('admin')) {
            return true;
        }
        return this.roles.includes('manager');
    }

    get isEmployee(): boolean {
        return this.roles.includes('employee');
    }

    get isScout(): boolean {
        return this.roles.includes('scouting');
    }

    public constructor(init?: Partial<UserModel>) {
        Object.assign(this, init);
        if (this.name) {
            this.displayName = this.name;
        }
    }
}

export interface NewAccountModel extends UserModel {
    password: string;
}
