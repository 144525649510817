import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { GoogleMapsModule } from '@angular/google-maps';
import { GestureConfig } from '@angular/material/core';
import {
    BrowserModule,
    HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { environment } from 'environments/environment';
import {
    PerfectScrollbarConfigInterface,
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
import { rootRouterConfig } from './app.routing';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { CartService } from './shared/services/cart.service';
import { DialogService } from './shared/services/dialogs/dialog.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { FarmsService } from './shared/services/farm.service';
import { OrdersService } from './shared/services/orders.service';
import { ProductsService } from './shared/services/products.service';
import { UsersService } from './shared/services/users.service';
import { SharedModule } from './shared/shared.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { FormsModule } from '@angular/forms';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

@NgModule({
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        PerfectScrollbarModule,
        GoogleMapsModule,
        AngularFireStorageModule,

        FormsModule,

        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        InMemoryWebApiModule.forRoot(InMemoryDataService, {
            passThruUnknownUrl: true,
        }),
        RouterModule.forRoot(rootRouterConfig, { useHash: false }),
        // CKEditorModule
    ],
    declarations: [AppComponent],
    providers: [
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },

        // REQUIRED IF YOU USE JWT AUTHENTICATION
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        ProductsService,
        OrdersService,
        UsersService,
        DialogService,
        FarmsService,
        CartService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
