import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth/auth.service';

interface IMenuItem {
    type: string; // Possible values: link/dropDown/icon/separator/extLink
    name?: string; // Used as display text for item and title for separator type
    state?: string; // Router state
    icon?: string; // Material icon name
    tooltip?: string; // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
}
interface IChildItem {
    type?: string;
    name: string; // Display text
    state?: string; // Router state
    icon?: string;
    sub?: IChildItem[];
}

interface IBadge {
    color: string; // primary/accent/warn/hex color codes(#fff000)
    value: string; // Display text
}

@Injectable()
export class NavigationService {
    constructor(private _authService: AuthService) {
        this._authService.user$.subscribe((user) => {
            if (user.roles && !user.roles.includes('admin')) {
                ['Manage Farm', 'Harvesting'].forEach((menuItem) => {
                    let index = this.authedMenu.findIndex(
                        (mi) => mi.name === menuItem
                    );
                    if (index > -1) {
                        this.authedMenu.splice(index, 1);
                    }
                });
            }

            if (user) {
                this.publishNavigationChange('authed');
            }
        });
    }

    plainMenu: IMenuItem[] = [
        {
            name: 'Home',
            type: 'link',
            tooltip: 'Home',
            icon: 'blur_on',
            state: 'home',
        },
        {
            name: 'Map',
            type: 'link',
            tooltip: 'Map',
            icon: 'map',
            state: 'home/map',
        },
        {
            name: 'Fresh Produce Platform',
            type: 'dropDown',
            tooltip: 'Fresh Produce Platform',
            icon: 'library_books',
            sub: [
                { name: 'Market Place', state: 'home/market' },
                {
                    name: 'My Products',
                    state: 'home/my-products',
                    icon: 'archive',
                },
                { name: 'Orders', state: 'home/orders', icon: 'credit_card' },
            ],
        },
    ];

    authedMenu: IMenuItem[] = [
        {
            name: 'Home',
            type: 'link',
            tooltip: 'Home',
            icon: 'blur_on',
            state: 'home',
        },
        {
            name: 'Fresh Produce Platform',
            type: 'dropDown',
            tooltip: 'Fresh Produce Platform',
            icon: 'library_books',
            sub: [
                { name: 'Market Place', state: 'home/market' },
                { name: 'Farm Map', state: 'home/map' },
                {
                    name: 'My Products',
                    state: 'home/my-products',
                    icon: 'archive',
                },
                { name: 'Orders', state: 'home/orders', icon: 'credit_card' },
            ],
        },
        {
            name: 'My Cycles',
            type: 'link',
            tooltip: 'Cycles',
            icon: 'published_with_changes',
            state: 'cycle/list',
        },
        {
            name: 'Browse public cycles',
            type: 'link',
            tooltip: 'Browse public cycles',
            icon: 'public',
            state: 'cycle/public',
        },
        {
            name: 'Manage Farm',
            type: 'dropDown',
            tooltip: 'Manage Farm',
            icon: 'list',
            sub: [
                {
                    name: 'Dashboard',
                    type: 'link',
                    state: 'farm/dashboard',
                },
                {
                    name: 'Map',
                    state: 'scouting/scouting-map',
                },
                {
                    name: 'Inventory',
                    type: 'dropDown',
                    sub: [
                        { name: 'Vehicles', state: 'inventory/vehicles' },
                        { name: 'Tools', state: 'inventory/tools' },
                        { name: 'Chemicals', state: 'inventory/chemicals' },
                    ],
                },
                {
                    name: 'Fields',
                    type: 'link',
                    state: 'fields/fields',
                },
                {
                    name: 'Orchards',
                    type: 'link',
                    state: 'orchards/list',
                },
                {
                    name: 'Employees',
                    type: 'link',
                    state: 'compliance/employee-management',
                },
                {
                    name: 'Archived Employees',
                    type: 'link',
                    state: 'compliance/employee-archive',
                },
                // { name: 'Add Employee', state: 'compliance/recruitment' },
                {
                    name: 'Employee Types',
                    state: 'compliance/employee-type',
                },
                {
                    name: 'Attendance Register',
                    state: 'compliance/attendance-register',
                },
                {
                    name: 'Linked Users',
                    state: 'farm/linked-users',
                },
            ],
        },
        {
            name: 'Harvesting',
            type: 'dropDown',
            icon: 'park',
            sub: [
                {
                    name: 'Dashboard',
                    state: 'harvesting/dashboard',
                },
                {
                    name: 'Bins',
                    state: 'harvesting/bins',
                },
                {
                    name: 'Schedules',
                    state: 'harvesting/schedules',
                },
                {
                    name: 'Schedules V2',
                    state: 'harvesting/schedule-list',
                },
                {
                    name: 'Picking & Sorting',
                    state: 'harvesting/picking',
                },
                {
                    name: 'Dehusking',
                    state: 'harvesting/dehusking',
                },
                {
                    name: 'Batches',
                    state: 'harvesting/batches',
                },
                {
                    name: 'Processors',
                    state: 'harvesting/processors',
                },
                // {
                //     name: 'Reports',
                //     state: 'harvesting/reports',
                // },
            ],
        },
        {
            name: 'Scouting',
            type: 'dropDown',
            tooltip: 'Scouting',
            icon: 'visibility',
            sub: [
                {
                    name: 'Surveys',
                    state: 'scouting/manage-surveys',
                },
                {
                    name: 'Survey Responses',
                    state: 'scouting/survey-response-list',
                },
                {
                    name: 'Scouting Map',
                    state: 'scouting/scouting-map',
                },
                // {
                //     name: 'Scouting Survey',
                //     state: 'scouting/nut-borer',
                // },
            ],
        },
        {
            name: 'Reports',
            type: 'dropDown',
            tooltip: 'Reports',
            icon: 'summarize',
            sub: [
                {
                    name: 'Attendance Report',
                    state: 'reporting/attendance-report',
                },
                {
                    name: 'Scouting Report',
                    state: 'reporting/survey-report',
                },
                {
                    name: 'Fuel report',
                    state: 'reporting/fuel-report',
                },
                {
                    name: 'Event Feedback report',
                    state: 'reporting/feedback-report',
                },
                {
                    name: 'Picking Report',
                    state: 'reporting/picking-report',
                },
                {
                    name: 'Picker Performance',
                    state: 'reporting/picker-performance',
                },
                {
                    name: 'Operations Report',
                    state: 'reporting/operations-report',
                },
            ],
        },
        {
            name: 'Community Projects',
            type: 'dropDown',
            tooltip: 'Community Projects',
            icon: 'supervised_user_circle',
            // state: 'general/community-projects',
            sub: [
                {
                    name: 'Projects',
                    state: 'general/community-projects',
                },
                {
                    name: 'Manage Projects',
                    state: 'general/manage-projects',
                },
            ],
        },
    ];

    // Icon menu TITLE at the very top of navigation.
    // This title will appear if any icon type item is present in menu.
    iconTypeMenuTitle: string = 'Frequently Accessed';
    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.plainMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // you can customize this method to supply different menu for
    // different user type.
    publishNavigationChange(menuType: string) {
        if (menuType == 'authed') {
            this.menuItems.next(this.authedMenu);
        } else {
            this.menuItems.next(this.plainMenu);
        }
    }
}
