import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { MessageDialogComponent } from './message-dialog.component';

interface Config {
    width?: string;
}

@Injectable()
export class DialogService {
    dialogRef: MatDialogRef<MessageDialogComponent>;
    constructor(private dialog: MatDialog) {}

    public showMessage(
        title: string,
        message: string,
        config: Config
    ): Observable<boolean> {
        this.dialogRef = this.dialog.open(MessageDialogComponent, {
            disableClose: false,
            backdropClass: 'light-backdrop',
        });
        this.dialogRef.updateSize(config.width);
        this.dialogRef.componentInstance.title = title;
        this.dialogRef.componentInstance.message = message;
        return this.dialogRef.afterClosed();
    }

    public showInfo(
        title: string,
        message: string,
        config: Config
    ): Observable<boolean> {
        this.dialogRef = this.dialog.open(InfoDialogComponent, {
            disableClose: false,
            backdropClass: 'light-backdrop',
            data: {
                title,
                message,
            },
        });
        this.dialogRef.updateSize(config.width);
        return this.dialogRef.afterClosed();
    }

    public close() {
        if (this.dialogRef) this.dialogRef.close();
    }
}
