<div class="text-center">
    <h1 class="m-0 pb-1" style="white-space: pre-wrap; line-height: 1.4">
        {{ title }}
    </h1>
    <div mat-dialog-content>
        <p>{{ message }}</p>
    </div>
    <div class="btn">
        <button
            (click)="onCancel()"
            style="margin-right: 5px"
            mat-raised-button
            mat-dialog-close
        >
            Cancel
        </button>
        <button
            (click)="onConfirm()"
            mat-raised-button
            mat-dialog-close
            color="warn"
        >
            Delete
        </button>
        <button
            *ngIf="data.groupId"
            (click)="deleteGroup()"
            mat-raised-button
            mat-dialog-close
            color="warn"
        >
            Delete Group
        </button>
    </div>
</div>
