import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { firestore } from 'firebase';
import { AuthService } from './auth/auth.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';

export class MarketProduct {
    id?: string;
    name: string;
    type: number;
    availableQuantity: number;
    units: string;
    useByDate: Date;
    ownerId: string;
    address: string;
    price: number;
    currency: string = "R";
    sellerName: string;
    category: string;
    imageUrl?: string;
    ownerProfileImageUrl?: string;
}

@Injectable()
export class ProductsService {
    products$: Observable<MarketProduct[]>;
    myProducts$: Observable<MarketProduct[]>;

    constructor(
        private _authService: AuthService,
        private _firestore: AngularFirestore
    ) {
        this.products$ = this._authService.user$.pipe(
            switchMap((user) => {
                return this._getProducts();
            })
        );

        this.myProducts$ = this._authService.user$.pipe(
            switchMap((user) => {
                return this._getMyProducts(user.uid);
            })
        );
    }

    _getProducts(): Observable<MarketProduct[]> {
        return this._firestore
            .collection<MarketProduct>('products')
            .snapshotChanges()
            .pipe(
                map((products) => {
                    return products.map((action) => {
                        const prod = action.payload.doc.data() as MarketProduct;
                        prod.id = action.payload.doc.id;
                        return prod;
                    });
                })
            );
    }

    _getMyProducts(uid: string): Observable<MarketProduct[]> {
        console.log(uid);
        return this._firestore
            .collection<MarketProduct>('products', (ref) =>
                ref.where('ownerId', '==', uid)
            )
            .snapshotChanges()
            .pipe(
                map((actions) => {
                    return actions.map((action) => {
                        const prod = action.payload.doc.data() as MarketProduct;
                        prod.id = action.payload.doc.id;
                        return prod;
                    });
                })
            );
    }

    public async addProduct(
        product: MarketProduct
    ): Promise<DocumentReference> {
        return this._firestore
            .collection('products')
            .add(Object.assign({}, product));
    }

    public async editProduct(product: MarketProduct): Promise<void> {
        return this._firestore
            .collection('products')
            .doc(product.id)
            .update(Object.assign({}, product));
    }
}
