import { Component, OnInit, Inject } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
} from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Component({
    selector: 'app-sign-in-dialog',
    templateUrl: './sign-in-dialog.component.html',
    styleUrls: ['./sign-in-dialog.component.css'],
})
export class SignInDialogComponent implements OnInit {
    signinForm: FormGroup;

    constructor(
        private _formBuilder: FormBuilder,
        private _authService: AuthService,
        public dialogRef: MatDialogRef<SignInDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public route: ActivatedRouteSnapshot,
        private router: Router
    ) {}

    ngOnInit(): void {
        this._authService.currentUser$.subscribe((user) => {
            if (user) {
                this.router.navigate([`/home/${this.route.routeConfig.path}`]);
                this.dialogRef.close();
            }
        });

        const password = new FormControl('', Validators.required);
        const confirmPassword = new FormControl(
            '',
            CustomValidators.equalTo(password)
        );

        this.signinForm = this._formBuilder.group({
            username: ['', [Validators.required, Validators.email]],
            password: password,
        });
    }

    async onSubmit() {
        if (!this.signinForm.invalid) {
            const signinData = this.signinForm.value;
            await this._authService.signin(
                signinData.username,
                signinData.password
            );

            this.router.navigate([`/home/${this.route.routeConfig.path}`]);
            this.dialogRef.close();
        }
    }
}
