import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SignInDialogComponent } from 'app/views/sessions/signin/sign-in-dialog/sign-in-dialog.component';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private jwtAuth: AuthService,
        private _dialog: MatDialog
    ) {}

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        if (await this.jwtAuth.isLoggedIn()) {
            return true;
        } else {
            this._dialog.open(SignInDialogComponent, {
                data: route,
            });
            // this.router.navigate(['/sessions/signin2'], {
            //     queryParams: {
            //         return: state.url,
            //     },
            // });
            return false;
        }
    }
}
