<div class="text-center mat-bg-primary pt-1 pb-1">
    <h6 class="m-0">Notifications</h6>
</div>
<mat-nav-list class="notification-list" role="list">
    <!-- Notification item -->
    <mat-list-item
        *ngFor="let n of notifications"
        class="notific-item"
        role="listitem"
        routerLinkActive="open"
    >
        <mat-icon [color]="n.color" class="notific-icon mr-1">{{
            n.icon
        }}</mat-icon>
        <a [routerLink]="[n.route || '/dashboard']">
            <div class="mat-list-text">
                <h4 class="message">{{ n.message }}</h4>
                <small class="time text-muted">{{ n.time | date }}</small>
            </div>
        </a>
    </mat-list-item>
</mat-nav-list>
<div class="text-center mt-1" *ngIf="notifications.length">
    <small
        ><a href="#" (click)="clearAll($event)"
            >Clear all notifications</a
        ></small
    >
</div>
