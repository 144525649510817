import {
    Component,
    OnInit,
    EventEmitter,
    Input,
    Output,
    Renderer2,
} from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth/auth.service';
import { OrdersService } from 'app/shared/services/orders.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileWizardComponent } from 'app/views/fpp/profile/profile-wizard/profile-wizard.component';
import { FarmsService, testFarm } from 'app/shared/services/farm.service';
import { CartService } from 'app/shared/services/cart.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header-side',
    templateUrl: './header-side.template.html',
    styleUrls: ['./header-side.component.css'],
})
export class HeaderSideComponent implements OnInit {
    @Input() notificPanel;
    public availableLangs = [
        {
            name: 'EN',
            code: 'en',
            flag: 'flag-icon-us',
        },
        // {
        //     name: 'ES',
        //     code: 'es',
        //     flag: 'flag-icon-es',
        // },
    ];
    currentLang = this.availableLangs[0];

    notifications: any[] = [];

    profileIncomplete = false;

    public egretThemes;
    public layoutConf: any;
    constructor(
        private themeService: ThemeService,
        private layout: LayoutService,
        public translate: TranslateService,
        private renderer: Renderer2,
        public jwtAuth: AuthService,
        private _ordersService: OrdersService,
        private _dialog: MatDialog,
        private _farmsService: FarmsService,
        public cartService: CartService,
        private _router: Router
    ) {}
    ngOnInit() {
        this.egretThemes = this.themeService.egretThemes;
        this.layoutConf = this.layout.layoutConf;
        this.translate.use(this.currentLang.code);

        this._ordersService.ordersReceived$.subscribe((orders) => {
            this.notifications = orders;
        });

        this._farmsService.farm$.subscribe((farm) => {
            if (farm) {
                let incompleteSteps = [0];
                let i = 0;
                for (const key in testFarm) {
                    if (Object.prototype.hasOwnProperty.call(testFarm, key)) {
                        const element = farm[key];
                        if (element == undefined) {
                            this.profileIncomplete = true;
                            incompleteSteps.push(i);
                        } else {
                        }
                    }
                }
            } else {
                this.profileIncomplete = true;
            }
        });
    }

    openWizard() {
        this._dialog.open(ProfileWizardComponent);
    }

    openCart() {
        this._router.navigateByUrl('home/checkout');
    }

    setLang(lng) {
        this.currentLang = lng;
        this.translate.use(lng.code);
    }
    changeTheme(theme) {
        // this.themeService.changeTheme(theme);
    }
    toggleNotific() {
        this.notificPanel.toggle();
    }
    toggleSidenav() {
        if (this.layoutConf.sidebarStyle === 'closed') {
            return this.layout.publishLayoutChange({
                sidebarStyle: 'full',
            });
        }
        this.layout.publishLayoutChange({
            sidebarStyle: 'closed',
        });
    }

    toggleCollapse() {
        // compact --> full
        if (this.layoutConf.sidebarStyle === 'compact') {
            return this.layout.publishLayoutChange(
                {
                    sidebarStyle: 'full',
                    sidebarCompactToggle: false,
                },
                { transitionClass: true }
            );
        }

        // * --> compact
        this.layout.publishLayoutChange(
            {
                sidebarStyle: 'compact',
                sidebarCompactToggle: true,
            },
            { transitionClass: true }
        );
    }

    onSearch(e) {
        //   console.log(e)
    }
}
