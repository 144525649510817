import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// SERVICES
import { ThemeService } from './services/theme.service';
import { NavigationService } from './services/navigation.service';
import { RoutePartsService } from './services/route-parts.service';
import { AuthGuard } from './guards/auth.guard';
import { UserRoleGuard } from './guards/user-role.guard';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { AppLoaderService } from './services/app-loader/app-loader.service';

import { SharedComponentsModule } from './components/shared-components.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { VehicleSelectorComponent } from './selectors/vehicle-selector/vehicle-selector.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { EmployeeSelectorComponent } from './selectors/employee-selector/employee-selector.component';
import { ToolSelectorComponent } from './selectors/tool-selector/tool-selector.component';
import { PhenologyStageSelectorComponent } from './selectors/phenology-stage-selector/phenology-stage-selector.component';
import { OrchardSelectorComponent } from './selectors/orchard-selector/orchard-selector.component';
import { MapAreaBuilderComponent } from 'app/views/fpp/map-area-builder/map-area-builder.component';
import { MatDialogModule } from '@angular/material/dialog';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RichTextDialogComponent } from './rich-text-dialog/rich-text-dialog.component';
import { EmployeeTypeSelectorComponent } from './selectors/employee-type-selector/employee-type-selector.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FieldSelectorComponent } from './selectors/field-selector/field-selector.component';
import { ChemicalSelectorComponent } from './selectors/chemical-selector/chemical-selector.component';
import { PhenologyCategorySelectorComponent } from './selectors/phenology-category-selector/phenology-category-selector.component';
import { SurveySelectorComponent } from './selectors/survey-selector/survey-selector.component';
import { DatetimeRangeSelectorModule } from './selectors/datetime-range-selector/datetime-range-selector.module';
import { CycleSelectorComponent } from './selectors/cycle-selector/cycle-selector.component';
import { ScheduleSelectorComponent } from './selectors/schedule-selector/schedule-selector.component';
import { PickerSelectorComponent } from './selectors/picker-selector/picker-selector.component';
import { BinSelectorComponent } from './selectors/bin-selector/bin-selector.component';
import { ProcessorSelectorComponent } from './selectors/processor-selector/processor-selector.component';
import { LineGraphComponent } from './graphs/line-graph/line-graph.component';
import { BarGraphComponent } from './graphs/bar-graph/bar-graph.component';
import { PieChartComponent } from './graphs/pie-chart/pie-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { AreaChartComponent } from './graphs/area-chart/area-chart.component';
import { ScheduleSelectorV2Component } from './selectors/schedule-selectorv2/schedule-selectorv2.component';

@NgModule({
    imports: [
        CKEditorModule,
        CommonModule,
        SharedComponentsModule,
        SharedPipesModule,
        SharedDirectivesModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatDialogModule,
        GoogleMapsModule,
        MatCardModule,
        FlexLayoutModule,
        MatChipsModule,
        MatTooltipModule,
        DatetimeRangeSelectorModule,

        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ],
    providers: [
        ThemeService,
        NavigationService,
        RoutePartsService,
        AuthGuard,
        UserRoleGuard,
        AppConfirmService,
        AppLoaderService,
    ],
    exports: [
        SharedComponentsModule,
        SharedPipesModule,
        SharedDirectivesModule,
        VehicleSelectorComponent,
        EmployeeSelectorComponent,
        ToolSelectorComponent,
        PhenologyStageSelectorComponent,
        OrchardSelectorComponent,
        MapAreaBuilderComponent,
        EmployeeTypeSelectorComponent,
        FieldSelectorComponent,
        ChemicalSelectorComponent,
        PhenologyCategorySelectorComponent,
        SurveySelectorComponent,
        CycleSelectorComponent,
        ScheduleSelectorComponent,
        ScheduleSelectorV2Component,
        PickerSelectorComponent,
        BinSelectorComponent,
        ProcessorSelectorComponent,
        LineGraphComponent,
        BarGraphComponent,
        PieChartComponent,
        AreaChartComponent,
    ],
    declarations: [
        VehicleSelectorComponent,
        EmployeeSelectorComponent,
        ToolSelectorComponent,
        PhenologyStageSelectorComponent,
        OrchardSelectorComponent,
        MapAreaBuilderComponent,
        RichTextDialogComponent,
        EmployeeTypeSelectorComponent,
        FieldSelectorComponent,
        ChemicalSelectorComponent,
        PhenologyCategorySelectorComponent,
        SurveySelectorComponent,
        CycleSelectorComponent,
        ScheduleSelectorComponent,
        PickerSelectorComponent,
        BinSelectorComponent,
        ProcessorSelectorComponent,
        LineGraphComponent,
        BarGraphComponent,
        PieChartComponent,
        AreaChartComponent,
        ScheduleSelectorV2Component,
    ],
})
export class SharedModule {}
