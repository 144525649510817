import { Injectable, Inject } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { firestore } from 'firebase';
import { AuthService } from './auth/auth.service';
import { switchMap, map, catchError, filter } from 'rxjs/operators';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { AdpLatLng } from './orchards.service';
import { UserModel } from '../models/user.model';

export const testFarm = {
    name: 'string',
    registrationNumber: 'string',
    vatNumber: 'string',
    waterCertificateNumber: 'string',
    uifNumber: 'string',
    coidNumber: 'string',
    latitude: '',
    longitude: '',
    physicalAddress1: 'string',
    physicalAddress2: 'string',
    postalAddress1: 'string',
    postalAddress2: 'string',
    municipalJurisdiction: 'string',
    district: 'string',
    primaryContact: 'string',
    reponsibility: 'string',
    businessPhone: 'string',
    businessCell: 'string',
    businessFax: 'string',
    email: 'string',
    agriculturalRole: 'string',
    farmHistory: 'string',
    productionHistory: 'string',
    farmInventory: ['string'],
    stakeholdersMentors: 'string',
};

export class Farm {
    id?: string;
    ownerId: string;
    name: string;
    businessEntityType: string;
    registrationNumber: string;
    vatNumber: string;
    waterCertificateNumber: string;
    uifNumber: string;
    coidNumber: string;
    physicalAddress1: string;
    physicalAddress2: string;
    postalAddress1: string;
    postalAddress2: string;
    municipalJurisdiction: string;
    district: string;
    primaryContact: string;
    reponsibility: string;
    businessPhone: string;
    businessCell: string;
    businessFax: string;
    email: string;
    agriculturalRole: string;
    farmHistory: string;
    productionHistory: string;
    farmInventory: string[];
    stakeholdersMentors: string;
    latitude: number;
    longitude: number;
    bounds: AdpLatLng[];
    area?: number;
    dieselCapacity?: number;
    scouting: boolean;
}

export class Consumables {
    id?: string;
    diesel: number;
}

@Injectable()
export class FarmsService {
    farm$: Observable<Farm>;
    scoutingFarms$: Observable<Farm[]>;
    consumables$: Observable<Consumables>;

    constructor(
        private _authService: AuthService,
        private _firestore: AngularFirestore
    ) {
        this.farm$ = this._authService.user$.pipe(
            switchMap((user) => {
                if (user.farmId && user.farmId != '') {
                    return this._getLinkedFarm(user.farmId);
                }
                return this._getFarm(user);
            })
        );

        this.farm$.subscribe((farm) => {
            console.log(farm.id);
        });

        this.consumables$ = this.farm$.pipe(
            switchMap((farm) => {
                if (farm) {
                    return this._getConsumables(farm.id);
                }
            })
        );

        this.scoutingFarms$ = this._authService.user$.pipe(
            switchMap((user) => {
                if (user.scoutingFarms) {
                    return combineLatest(
                        user.scoutingFarms.map((farmId) => {
                            return this._getLinkedFarm(farmId);
                        })
                    );
                }
            })
        );
    }

    _getFarm(user: UserModel): Observable<Farm> {
        return this._firestore
            .collection<Farm>('farms', (ref) =>
                ref.where('ownerId', '==', user.uid)
            )
            .snapshotChanges()
            .pipe(
                map((products) => {
                    if (products.length) {
                        const farm = products[0].payload.doc.data() as Farm;
                        farm.id = products[0].payload.doc.id;
                        return farm;
                    }
                })
            );
    }

    _getLinkedFarm(farmId: string): Observable<Farm> {
        return this._firestore
            .collection<Farm>('farms')
            .doc(farmId)
            .snapshotChanges()
            .pipe(
                map((products) => {
                    if (products) {
                        const farm = products.payload.data() as Farm;
                        farm.id = products.payload.id;
                        return farm;
                    }
                })
            );
    }

    _getConsumables(farmId: string): Observable<Consumables> {
        return this._firestore
            .collection<Consumables>('consumables')
            .doc(farmId)
            .snapshotChanges()
            .pipe(
                map((products) => {
                    if (products) {
                        const consumables =
                            products.payload.data() as Consumables;
                        consumables.id = products.payload.id;
                        return consumables;
                    }
                })
            );
    }

    public async addFarm(farm: any): Promise<DocumentReference> {
        return this._firestore.collection('farms').add(Object.assign({}, farm));
    }

    public async editFarm(update: any): Promise<void> {
        return this._firestore
            .collection('farms')
            .doc(update.id)
            .update(Object.assign({}, update));
    }

    public async updateFarmField(farmId: string, update: any) {
        return this._firestore
            .collection('farms')
            .doc(farmId)
            .update(Object.assign({}, update));
    }

    public async updateConsumables(farmId: string, consumables: Consumables) {
        return this._firestore
            .collection('consumables')
            .doc(farmId)
            .set(Object.assign({}, consumables), { merge: true });
    }

    public async addToUnsubscribeList(
        email: string,
        farmId: string
    ): Promise<DocumentReference> {
        return this._firestore.collection('unsubscribed').add({
            email,
            farmId,
        });
    }
}
