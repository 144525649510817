<form [formGroup]="signinForm" (ngSubmit)="onSubmit()">
    <h2 mat-dialog-title align="center">Please sign in to your account</h2>
    <mat-dialog-content class="mat-typography">
        <div class="mb-48" fxLayout="row wrap" fxLayoutAlign="center center">
            <img width="300px" src="assets/images/home-logo.png" alt="" />
        </div>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input
                matInput
                formControlName="username"
                type="email"
                name="email"
                placeholder="Email"
            />
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Password</mat-label>
            <input
                matInput
                formControlName="password"
                type="password"
                name="password"
                placeholder="********"
            />
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button
            class="fullWidth"
            mat-raised-button
            color="primary"
            cdkFocusInitial
        >
            Sign in
        </button>
        <button
            class="fullWidth"
            [routerLink]="'/sessions/signup2'"
            mat-button
            mat-dialog-close
        >
            Create an account
        </button>
    </mat-dialog-actions>
</form>
