import {
    Component,
    OnInit,
    ViewChild,
    ChangeDetectorRef,
    ViewEncapsulation,
    Inject,
} from '@angular/core';
import {
    FormBuilder,
    Validators,
    FormGroup,
    FormControl,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { FarmsService, Farm, testFarm } from 'app/shared/services/farm.service';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { take } from 'rxjs/operators';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { LocationSelectorComponent } from '../../location-selector/location-selector.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { MapAreaBuilderComponent } from '../../map-area-builder/map-area-builder.component';
import { AdpLatLng } from 'app/shared/services/orchards.service';

class FarmInput {
    name: string;
    placeholder: string;
    message: string;
    inputs: number;
    type: string;
    options?: string[];
}

@Component({
    selector: 'app-profile-wizard',
    templateUrl: './profile-wizard.component.html',
    styleUrls: ['./profile-wizard.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ProfileWizardComponent implements OnInit {
    farmDetails: FormGroup;

    @ViewChild('stepper')
    stepper: MatStepper;

    farm: Farm;

    farmFormFields: FarmInput[] = [
        {
            name: 'name',
            placeholder: 'Farm Name',
            message: 'What is the name of your farm?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'businessEntityType',
            placeholder: 'Business Entity',
            message: 'What is your business entity type?',
            inputs: 1,
            type: 'select',
            options: [
                'Sole Proprietor',
                'Co-Operative',
                'Community Network',
                'Closed Corporation',
                'PTY(Ltd)',
            ],
        },
        {
            name: 'registrationNumber',
            placeholder: 'Registration Number',
            message: 'What is your registration number?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'vatNumber',
            placeholder: 'VAT Number',
            message: 'What is your VAT number?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'waterCertificateNumber',
            placeholder: 'Water Certificate Number',
            message: 'What is your Water Certificate Number?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'uifNumber',
            placeholder: 'UIF Number',
            message: 'What is your UIF Number?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'coidNumber',
            placeholder: 'COID Number',
            message: 'What is your COID Number?',
            inputs: 1,
            type: 'input',
        },
        // {
        //     name: 'location',
        //     placeholder: 'Location',
        //     message: 'Define the boundaries of your farm',
        //     inputs: 1,
        // },
        {
            name: 'physicalAddress',
            placeholder: 'Physical Address',
            message: 'What is your farms Physical Address?',
            inputs: 2,
            type: 'input',
        },
        {
            name: 'postalAddress',
            placeholder: 'Postal Address',
            message: 'What is your farms Postal Address?',
            inputs: 2,
            type: 'input',
        },
        {
            name: 'municipalJurisdiction',
            placeholder: 'Municipal Jurisdiction',
            message: 'What is your Municipal Jurisdiction',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'district',
            placeholder: 'District',
            message: 'In what district is your farm located?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'primaryContact',
            placeholder: 'Contact Number',
            message: 'What is the primary contact number?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'reponsibility',
            placeholder: 'Reponsibility',
            message: 'What is your reponsibility?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'businessPhone',
            placeholder: 'Business Phone',
            message: 'What is your business phone number?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'businessCell',
            placeholder: 'Business Cell',
            message: 'What is your  business cell number?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'businessFax',
            placeholder: 'Business Fax',
            message: 'What is your  business fax number?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'email',
            placeholder: 'Business Email',
            message: 'What is your  is your business email?',
            inputs: 1,
            type: 'input',
        },
        {
            name: 'agriculturalRole',
            placeholder: 'Role',
            message: 'What is your role in agriculture?',
            inputs: 1,
            type: 'input',
        },
    ];
    coords: any;
    bounds: AdpLatLng[];

    constructor(
        private _farmsService: FarmsService,
        private _authService: AuthService,
        private _cdRef: ChangeDetectorRef,
        private _dialog: MatDialog,
        public dialogRef: MatDialogRef<ProfileWizardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _loader: AppLoaderService
    ) {
        this._farmsService.farm$.subscribe((farm) => {
            if (farm) {
                this.farm = farm;
            }
        });
        this._farmsService.farm$.pipe(take(1)).subscribe((farm) => {
            if (farm) {
                this.farm = farm;

                if (this.farm.latitude && this.farm.longitude) {
                    // this.coords = {
                    //     lat: this.farm.latitude,
                    //     lng: this.farm.longitude,
                    // };
                    this.bounds = this.farm.bounds;
                }

                this.farmDetails.patchValue({
                    name: farm.name,
                    businessEntityType: farm.businessEntityType,
                    registrationNumber: farm.registrationNumber,
                    vatNumber: farm.vatNumber,
                    waterCertificateNumber: farm.waterCertificateNumber,
                    uifNumber: farm.uifNumber,
                    coidNumber: farm.coidNumber,
                    physicalAddress1: farm.physicalAddress1,
                    physicalAddress2: farm.physicalAddress2,
                    postalAddress1: farm.postalAddress1,
                    postalAddress2: farm.postalAddress2,
                    municipalJurisdiction: farm.municipalJurisdiction,
                    district: farm.district,
                    primaryContact: farm.primaryContact,
                    reponsibility: farm.reponsibility,
                    businessPhone: farm.businessPhone,
                    businessCell: farm.businessCell,
                    businessFax: farm.businessFax,
                    email: farm.email,
                    agriculturalRole: farm.agriculturalRole,
                    farmHistory: farm.farmHistory,
                    productionHistory: farm.productionHistory,
                    // farmInventory: this._formBuilder.array([
                    //     new FormControl('', [Validators.required]),
                    // ]),
                    stakeholdersMentors: farm.stakeholdersMentors,
                });

                // let incompleteSteps = [];
                // let i = 0;
                // for (const key in testFarm) {
                //     if (Object.prototype.hasOwnProperty.call(testFarm, key)) {
                //         const element = this.farm[key];
                //         if (element == undefined) {
                //             incompleteSteps.push(i);
                //         } else {
                //             (this.stepper.steps as any)._results[
                //                 i
                //             ].interacted = true;
                //         }

                //         i++;
                //     }
                // }

                // if (incompleteSteps.length) {
                //     setTimeout(() => {
                //         this.stepper.selectedIndex = incompleteSteps[0];
                //     }, 300);
                // }

                this._cdRef.detectChanges();
            } else {
                console.log('No farm...');
            }
        });
    }

    // async selectLocation(): Promise<void> {
    //     const ref = this._dialog.open(LocationSelectorComponent, {
    //         width: '600px',
    //     });
    //     ref.afterClosed().subscribe((result) => {
    //         this.coords = result;
    //     });
    // }

    async selectLocation(): Promise<void> {
        const ref = this._dialog.open(MapAreaBuilderComponent, {
            width: '600px',
        });
        ref.afterClosed().subscribe((result) => {
            console.log(result);
            this.bounds = result;
        });
    }

    done() {
        this.dialogRef.close();
    }

    async skip() {
        this.stepper.next();
    }

    async next(input: FarmInput) {
        this._loader.open();
        if (this.farm == undefined) {
            await this._farmsService.addFarm({
                name: this.farmDetails.get(input.name).value,
                ownerId: this._authService.user.uid,
                email: this._authService.user.email,
                scouting: true,
            });
        } else {
            //TODO: Remember what this does.
            let update = {};
            if (input.name != 'location') {
                if (input.inputs > 1) {
                    for (let i = 0; i < input.inputs; i++) {
                        update[`${input.name}${i + 1}`] = this.farmDetails.get(
                            `${input.name}${i + 1}`
                        ).value;
                    }
                } else {
                    update[`${input.name}`] = this.farmDetails.get(
                        input.name
                    ).value;
                }
            }

            if (input.name == 'location') {
                update = {
                    bounds: this.bounds,
                };
            }

            await this._farmsService.updateFarmField(this.farm.id, update);
        }
        this._loader.close();
        this.stepper.next();
    }

    isInValid(input: FarmInput): boolean {
        if (input.name == 'location') {
            return this.bounds == undefined;
        }

        let invalid = true;

        if (input.inputs > 1) {
            let values = [];
            for (let i = 0; i < input.inputs; i++) {
                values.push(
                    this.farmDetails.get(`${input.name}${i + 1}`).invalid
                );
            }
            invalid = values.indexOf(true) > -1;

            return invalid;
        }

        if (this.farmDetails.get(input.name)) {
            return this.farmDetails.get(input.name).invalid;
        }

        return false;
    }

    ngOnInit(): void {
        this.farmDetails = new FormGroup({
            name: new FormControl('', [Validators.required]),
            businessEntityType: new FormControl('', [Validators.required]),
            registrationNumber: new FormControl('', [Validators.required]),
            vatNumber: new FormControl('', [Validators.required]),
            waterCertificateNumber: new FormControl('', [Validators.required]),
            uifNumber: new FormControl('', [Validators.required]),
            coidNumber: new FormControl('', [Validators.required]),
            physicalAddress1: new FormControl('', [Validators.required]),
            physicalAddress2: new FormControl('', [Validators.required]),
            postalAddress1: new FormControl('', [Validators.required]),
            postalAddress2: new FormControl('', [Validators.required]),
            municipalJurisdiction: new FormControl('', [Validators.required]),
            district: new FormControl('', [Validators.required]),
            primaryContact: new FormControl('', [Validators.required]),
            reponsibility: new FormControl('', [Validators.required]),
            businessPhone: new FormControl('', [Validators.required]),
            businessCell: new FormControl('', [Validators.required]),
            businessFax: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.email]),
            agriculturalRole: new FormControl('', [Validators.required]),
            // farmHistory: new FormControl('', [Validators.required]),
            // productionHistory: new FormControl('', [Validators.required]),
            // farmInventory: this._formBuilder.array([
            //     new FormControl('', [Validators.required]),
            // ]),
            // stakeholdersMentors: new FormControl('', [Validators.required]),
        });
    }
}
