<h2 mat-dialog-title align="center">Complete your business profile</h2>
<mat-dialog-content class="mat-typography">
    <mat-horizontal-stepper [linear]="true" #stepper>
        <form [formGroup]="farmDetails">
            <mat-step
                [stepControl]="firstFormGroup"
                *ngFor="let farmInput of farmFormFields"
                optional="true"
            >
                <h1>{{ farmInput.message }}</h1>

                <div align="center" style="width: 600px; margin: auto">
                    <div
                        *ngIf="
                            farmInput.name != 'location' &&
                            farmInput.type === 'input'
                        "
                    >
                        <div *ngIf="farmInput.inputs == 2">
                            <mat-form-field
                                appearance="outline"
                                class="full-width"
                            >
                                <mat-label>{{
                                    farmInput.placeholder
                                }}</mat-label>
                                <input
                                    matInput
                                    [placeholder]="farmInput.placeholder"
                                    formControlName="{{ farmInput.name }}1"
                                    required
                                />
                            </mat-form-field>
                            <mat-form-field
                                appearance="outline"
                                class="full-width"
                            >
                                <mat-label>{{
                                    farmInput.placeholder
                                }}</mat-label>
                                <input
                                    matInput
                                    [placeholder]="farmInput.placeholder"
                                    formControlName="{{ farmInput.name }}2"
                                    required
                                />
                            </mat-form-field>
                        </div>

                        <div *ngIf="farmInput.inputs == 1">
                            <mat-form-field
                                appearance="outline"
                                class="full-width"
                            >
                                <mat-label>{{
                                    farmInput.placeholder
                                }}</mat-label>
                                <input
                                    matInput
                                    [placeholder]="farmInput.placeholder"
                                    [formControlName]="farmInput.name"
                                    required
                                />
                            </mat-form-field>
                        </div>
                    </div>

                    <div
                        *ngIf="
                            farmInput.name == 'location' &&
                            farmInput.type === 'input'
                        "
                    >
                        <div style="text-align: center; margin: 25px">
                            <button
                                mat-raised-button
                                (click)="selectLocation()"
                                color="primary"
                            >
                                Select Location
                            </button>
                        </div>
                    </div>

                    <div *ngIf="farmInput.type === 'select'">
                        <div style="text-align: center; margin: 25px">
                            <h4>{{ farmInput.name }}</h4>
                            <mat-form-field appearance="outline">
                                <mat-label>Select</mat-label>
                                <select
                                    matNativeControl
                                    [formControlName]="farmInput.name"
                                >
                                    <option
                                        [value]="option"
                                        *ngFor="let option of farmInput.options"
                                    >
                                        {{ option }}
                                    </option>
                                </select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <button mat-raised-button matStepperPrevious>Back</button>
                    <div>
                        <button
                            mat-raised-button
                            (click)="next(farmInput)"
                            [disabled]="isInValid(farmInput)"
                            color="primary"
                        >
                            Next
                        </button>
                        <button
                            mat-raised-button
                            (click)="skip(farmInput)"
                            *ngIf="isInValid(farmInput)"
                            color="primary"
                        >
                            Skip
                        </button>
                    </div>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Done</ng-template>
                <h1 style="text-align: center">
                    You have completed your business profile!
                </h1>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <button mat-raised-button matStepperPrevious>Back</button>
                    <button mat-raised-button (click)="done()" color="primary">
                        Done
                    </button>
                </div>
            </mat-step>
        </form>
    </mat-horizontal-stepper>
</mat-dialog-content>
<!-- <mat-dialog-actions align="space-between">
    <button class="fullWidth" mat-raised-button color="primary" cdkFocusInitial>
        Sign in
    </button>
    <button
        class="fullWidth"
        [routerLink]="'/sessions/signup2'"
        mat-button
        mat-dialog-close
    >
        Create an account
    </button>
</mat-dialog-actions> -->
