export {}; // to make it a module

declare global {
    // to access the global type String
    interface String {
        truncate(max: number, decorator: string): string;
        replaceAll(find: string, replace: string): string;
    }
}

// then the actual code
String.prototype.truncate = function (max, decorator) {
    decorator = decorator || '...';
    return this.length > max ? this.substring(0, max) + decorator : this;
};

String.prototype.replaceAll = function (find: string, replace: string): string {
    return this.replace(new RegExp(find, 'g'), replace);
};
