<mat-toolbar class="topbar">
    <!-- Sidenav toggle button -->
    <button
        *ngIf="layoutConf.sidebarStyle !== 'compact'"
        mat-icon-button
        id="sidenavToggle"
        (click)="toggleSidenav()"
        matTooltip="Toggle Hide/Open"
    >
        <mat-icon>menu</mat-icon>
    </button>

    <!-- Search form -->
    <!-- <div fxFlex fxHide.lt-sm="true" class="search-bar">
    <form class="top-search-form">
      <mat-icon role="img">search</mat-icon>
      <input autofocus="true" placeholder="Search" type="text" />
    </form>
  </div> -->

    <button
        mat-raised-button
        color="primary"
        class="whiteText"
        cdkFocusInitial
        *ngIf="profileIncomplete"
        (click)="openWizard()"
    >
        Complete your profile
        <mat-icon>forward</mat-icon>
    </button>

    <span fxFlex></span>
    <!-- Language Switcher -->
    <!-- <button mat-button [matMenuTriggerFor]="menu">
        <span class="flag-icon {{currentLang.flag}} mr-05"></span>
        <span>{{currentLang.name}}</span>
    </button>
    <mat-menu #menu="matMenu">
        <button
            mat-menu-item
            *ngFor="let lang of availableLangs"
            (click)="setLang(lang)"
        >
            <span class="flag-icon mr-05 {{lang.flag}}"></span>
            <span>{{lang.name}}</span>
        </button>
    </mat-menu> -->

    <div class="cartData">
        <button
            mat-raised-button
            color="primary"
            class="whiteText"
            cdkFocusInitial
            *ngIf="(cartService.cart | async).products.length > 0"
            (click)="openCart()"
        >
            View cart
            <mat-icon *ngIf="(cartService.cart | async).products.length > 0"
                matBadge="{{(cartService.cart | async).products?.length}}"
                matBadgeColor="accent"
                >add_shopping_cart</mat-icon
            >
        </button>
        <!-- {{(cartService.cart | async).products.length}} -->
    </div>

    <egret-search-input-over
        placeholder="Country (e.g. US)"
        resultPage="/search"
    >
    </egret-search-input-over>
    <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

    <!-- Notification toggle button -->
    <button
        mat-icon-button
        matTooltip="Notifications"
        (click)="toggleNotific()"
        [style.overflow]="'visible'"
        class="topbar-button-right"
    >
        <mat-icon>notifications</mat-icon>
        <span
            class="notification-number mat-bg-warn"
            *ngIf="notifications.length > 0"
            >{{notifications.length}}</span
        >
    </button>
    <!-- Top left user menu -->
    <button
        mat-icon-button
        [matMenuTriggerFor]="accountMenu"
        class="topbar-button-right img-button"
    >
        <!-- <img src="assets/images/face-7.jpg" alt="" /> -->

        <img [src]="jwtAuth.user?.imageUrl" class="mat-elevation-z1" alt="" />
    </button>

    <mat-menu #accountMenu="matMenu">
        <button mat-menu-item [routerLink]="['//home/profile']">
            <mat-icon>account_box</mat-icon>
            <span>Profile</span>
        </button>
        <!-- <button mat-menu-item [routerLink]="['/profile/settings']">
            <mat-icon>settings</mat-icon>
            <span>Account Settings</span>
        </button>
        <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>Disable alerts</span>
        </button> -->
        <button mat-menu-item (click)="jwtAuth.signout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ "SIGNOUT" | translate }}</span>
        </button>
    </mat-menu>
</mat-toolbar>
