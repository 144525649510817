<div class="sidebar-panel">
    <div
        id="sidebar-top-scroll-area"
        [perfectScrollbar]
        class="navigation-hold"
        fxLayout="column"
    >
        <app-sidenav [items]="menuItems"></app-sidenav>
    </div>
</div>
