import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserModel } from '../models/user.model';

@Injectable()
export class UserRoleGuard implements CanActivate {
    constructor(
        private router: Router,
        private _authService: AuthService,
        private snack: MatSnackBar
    ) {}

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        // var user = this.jwtAuth.getUser();
        // return true;
        const user: UserModel = await this._authService.waitForUserModel();
        if (
            user &&
            route.data &&
            route.data.requiredRoles &&
            route.data.requiredRoles.some((role) => {
                return user.roles.includes(role);
            })
        ) {
            return true;
        } else {
            this.snack.open('You do not have access to this page!', 'OK', {
                verticalPosition: 'top',
                horizontalPosition: 'center',
            });
            return false;
        }
    }
}
