import { config } from 'config';

export const environment = {
    production: false,
    apiURL: config.apiUrl,
    firebase: {
        apiKey: 'AIzaSyD-3fkxeuBeJlara8SECdlgGe3qKwmacIM',
        authDomain: 'produsource-60979.firebaseapp.com',
        databaseURL: 'https://produsource-60979.firebaseio.com',
        projectId: 'produsource-60979',
        storageBucket: 'produsource-60979.appspot.com',
        messagingSenderId: '201155041817',
        appId: '1:201155041817:web:2c21414365e3ab69d12888',
        measurementId: 'G-8BFP0P219X',
    },
};
