<mat-dialog-content class="mat-typography">
    <div class="mapContainer">
        <p>Starting clicking on points on the map to draw out an area.</p>
        <google-map
            #map
            height="100%"
            width="100%"
            [zoom]="10"
            [center]="center"
            (mapClick)="mapClicked($event)"
        >
            <!-- <map-marker
                *ngFor="location of locations"
                #markerElem
                [position]="location"
                [options]="markerOptions"
                (mapClick)="openInfo(markerElem, marker.data)"
            >
            </map-marker> -->

            <map-marker
                #markerElem
                *ngIf="marker"
                [position]="marker.position"
                [label]="marker.label"
                [title]="marker.title"
            >
            </map-marker>
            <!-- (mapClick)="openInfo(markerElem, marker.data)" -->
            <map-polygon
                *ngFor="let other of otherBoundaries"
                [paths]="other"
                [options]="otherOptions"
                (polygonClick)="handleClick($event)"
                (polygonRightclick)="handleRightclick($event)"
            ></map-polygon>

            <map-polygon
                [paths]="parentBoundary"
                [options]="parentOptions"
                (polygonClick)="handleClick($event)"
                (polygonRightclick)="handleRightclick($event)"
            ></map-polygon>

            <map-polygon
                #poly
                [paths]="locations"
                [options]="options"
                (polygonClick)="handleClick($event)"
                (polygonRightclick)="handleRightclick($event)"
            ></map-polygon>

            <!-- <map-circle [center]="center" [radius]="radius"></map-circle> -->
        </google-map>
    </div>
</mat-dialog-content>

<div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    style="margin-top: 5px"
>
    <div fxLayout="column" fxFlex="50">
        <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-raised-button (click)="undo()">
                Undo <mat-icon>undo</mat-icon>
            </button>
            <button
                class="fullWidth"
                (click)="clear()"
                mat-raised-button
                color="warn"
            >
                Clear
            </button>
        </div>
    </div>
    <div fxLayout="column" fxFlex="50">
        <div fxLayout="row" fxLayoutAlign="space-evenly center"></div>
    </div>
</div>

<mat-dialog-actions align="end">
    <button class="fullWidth" mat-button cdkFocusInitial (click)="cancel()">
        Cancel
    </button>
    <button
        class="fullWidth"
        (click)="save()"
        mat-raised-button
        color="primary"
        mat-dialog-close
    >
        Save
    </button>
</mat-dialog-actions>
