import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { OrdersService } from 'app/shared/services/orders.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {
    @Input() notificPanel;

    notifications = [];

    constructor(
        private router: Router,
        private _ordersService: OrdersService
    ) {}

    ngOnInit() {
        this.router.events.subscribe((routeChange) => {
            if (routeChange instanceof NavigationEnd) {
                this.notificPanel.close();
            }
        });

        this._ordersService.ordersReceived$.subscribe((orders) => {
            this.notifications = orders.map((order) => {
                return {
                    message: `New order from ${order.buyerName}`,
                    color: 'accent',
                    icon: 'chat',
                    route: '/home/orders',
                    time: (order.orderDate as any).toDate(),
                };
            });
        });
    }
    clearAll(e) {
        e.preventDefault();
        this.notifications = [];
    }
}
